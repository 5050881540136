<template>
	<v-app id="main">
		<!-- Side Menu  -->
			<!-- <page-side-menu @update-drawer="update_drawer"></page-side-menu> -->
		<!-- End side menu -->

		<!-- Header -->
			<page-header></page-header>
		<!-- End header -->

		<v-main>
			<!-- Content -->
				<router-view></router-view>
			<!-- End Content -->
		</v-main>
	</v-app>
</template>

<script>
	export default {
		name: 'PagesIndex',

		components: {
			PageHeader: () => import('./common/Header'),
			// PageSideMenu: () => import('./common/SideMenu'),
		},
	}
</script>